<template>
    <button
        @click.prevent="clear"
        class="mr-2"
        :class="active ? 'text-gray-400' : 'text-gray-600'">
        <svg class="w-6 h-6 " fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path>
        </svg>
    </button>
</template>

<script>
export default {
    props: ['active'],

    methods: {
        clear() {
            if (!this.active) {
                return;
            }
            if (confirm('Are you sure you want to clear all archived notes?')) {
                this.$emit('clear');
            }
        },
    },
};
</script>
